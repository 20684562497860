<div class="error-page">
  <p class="message">Something went wrong!</p>
  <p class="sub-message"
     *ngIf="errorState else defaultBlock">{{errorState.clientMessage}}</p>
  <ng-template #defaultBlock>
    <p class="sub-message">The page you're looking for either does not exist or the requested resource is currently not
      available!</p>
  </ng-template>
  <button
    type="submit"
    class="btn btn-action ml-3"
    (click)="navigate()">{{!errorOnLogin ? 'Go to Home Page' : 'Go to login page'}}</button>
</div>
