/**
 * @author atanas.kovachev
 * @email akovachev@objectsystems.com
 * @desc Reporter resolver component
 */
import {
  Component,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services';
import { Error } from '../../../shared/models/error-model.model';

@Component ( {
  selector: 'reporter-reporter-resolver-error',
  templateUrl: './reporter-resolver-error.component.html',
  styleUrls: [ './reporter-resolver-error.component.scss' ]
} )
export class ReporterResolverErrorComponent {

  errorState: Error;
  errorOnLogin = false;

  constructor( private router: Router, private authService: AuthService ) {
    const errorRouteState = this.router.getCurrentNavigation ().extras.state;
    this.errorState = errorRouteState ? errorRouteState.error : undefined;
    this.errorOnLogin = errorRouteState && errorRouteState.fromUrl && errorRouteState.fromUrl === '/login';
  }

  navigate(): void {
    if ( this.errorOnLogin ) {
      this.authService.logout ().toPromise ().then ( d => {
        this.router.navigate ( [ '/login' ] );
      } );
    } else {
      this.router.navigate ( [ '/home' ] );
    }
  }
}
